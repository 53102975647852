import dynamic from 'next/dynamic';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { ICookiesPopupProps } from '../components/common/CookiesPopUp';
import { IReviewProps } from '../components/common/blocks/Reviews';
import { ITrustedByProps } from '../components/common/blocks/TrustedBy';
import { IContactFormValues } from '../components/common/forms/ContactForm';
import { IFooterProps } from '../components/common/layout/Footer';
import Layout from '../components/common/layout/Layout';
import { INavBarProps } from '../components/common/layout/NavBar';
import { IContactProps } from '../components/contact-us/Contact';
import FeaturedServices, { IFeaturedServiceProps } from '../components/home/FeaturedServices';
import Hero, { IHeroComponentProps } from '../components/home/Hero';
import ShortcutCountryList, { IShortcutCountryList } from '../components/home/ShortcutCountryList';
import {
  IContactMethod,
  IContactUsForm,
  ICountryRegion,
  ICustomerReview,
  ICustomerReviewsComponent,
  IHomePage,
  ILayoutPageProps,
  IMedia,
  ITrademarkLanding,
  ITrustedByComponent,
} from '../models/strapi.models';
import { fetchStrapi, postStrapi } from '../services/api.service';
import { logError } from '../services/log.service';
import { getRoutes } from '../shared/useBuild';
import { buildApiKey, getContactMethods, populateCookiesPopUpProps, populateFooterProps, populateNavBarProps } from '../shared/useCommon';
import {
  flatStrapiCollection,
  flatStrapiObject,
  getAlternateLinks,
  getCanonicalLink,
  getContentLanguageCode,
  getLocalizedSlugByCountryIso,
  getTranslation,
  keepObjectProperties,
  randomNumber,
} from '../shared/useUtils';
import { IAppPage } from '../store/slices/appSlice';
import { NextPageWithLayout } from './_app';

export interface IHomePageProps extends ILayoutPageProps {
  hero: IHeroComponentProps;
  featuredServices: IFeaturedServiceProps;
  shortcutCountryList: IShortcutCountryList;
  trustedBy: ITrustedByComponent;
  customerReviews: ICustomerReviewsComponent;
  reviews: ICustomerReview[];
  contactUsForm: IContactUsForm;
  contactUsFormSuccessMessage: string;
  contactUsFormErrorMessage: string;
  contactMethods: IContactMethod[];
  alternativeLinks: { hrefLang: string; href: string }[];
  canonicalLink: string;
}

const HomePage: NextPageWithLayout<IHomePageProps> = (props: IHomePageProps) => {
  const router = useRouter();
  const [hydrated, setHydrated] = useState(false);
  const [submitResultTypeState, setSubmitResultTypeState] = useState<'success' | 'error' | undefined>(undefined);

  const submitContactForm = async (formData: IContactFormValues) => {
    const payload = {
      data: {
        ...formData,
        sourceUrl: window.location.href,
      },
    };

    try {
      await postStrapi<any>(`/contact-submissions`, payload);
      setSubmitResultTypeState('success');
    } catch (error) {
      setSubmitResultTypeState('error');
      logError(['Error submitting contact form', error]);
    }
  };

  const trustedByProps: ITrustedByProps = {
    title: props.trustedBy.title,
    companies: props.trustedBy.logos.map((x) => ({
      company: 'company',
      image: <Image src={x.url as string} fill sizes="175px" alt={x.alternativeText || ''} />,
    })),
  };

  const contactProps: IContactProps = {
    contactMethods: props.contactMethods.map((x) => ({
      type: x.type,
      label: getTranslation(x.labelTranslations, router.locale || 'en').text as string,
      value: x.valueTranslations && x.valueTranslations.length > 0 ? (getTranslation(x.valueTranslations, router.locale || 'en').text as string) : undefined,
    })),
    titleTagName: 'h2',
    formLabels: {
      title: props.contactUsForm.title,
      subTitle: props.contactUsForm.subTitle,
      name: props.contactUsForm.nameLabel,
      email: props.contactUsForm.emailLabel,
      message: props.contactUsForm.messageLabel,
      submit: props.contactUsForm.submitLabel,
      submitSuccessMessage: props.contactUsFormSuccessMessage,
      submitErrorMessage: props.contactUsFormErrorMessage,
    },
    onSubmit: submitContactForm,
    submitResultType: submitResultTypeState,
  };

  const reviewsProps: IReviewProps = useMemo(() => {
    const randomReview = props.reviews[randomNumber(0, props.reviews.length - 1)];
    return {
      title: props.customerReviews.title,
      subTitle: props.customerReviews.subTitle,
      description: props.customerReviews.description,
      icon: props.customerReviews.logo?.url ? <Image fill sizes="220px" loading="lazy" src={props.customerReviews.logo.url} alt="Shopper's Approved" /> : undefined,
      iconLink: props.customerReviews.logoLink,
      review: {
        text: randomReview.content,
        location: randomReview.customerLocation,
        username: randomReview.customerName,
        score: randomReview.score,
      },
    };
  }, [props.customerReviews.description, props.customerReviews.logo?.url, props.customerReviews.logoLink, props.customerReviews.subTitle, props.customerReviews.title, props.reviews]);

  const TrustedByComponent = dynamic(() => import('../components/common/blocks/TrustedBy'));
  const ReviewsComponent = dynamic(() => import('../components/common/blocks/Reviews'));
  const ContactComponent = dynamic(() => import('../components/contact-us/Contact'));

  useEffect(() => {
    setHydrated(true);
  }, []);

  return (
    <div className="page-container">
      <Head>
        <title>{props.meta.title}</title>
        <meta name="description" content={props.meta.description} />
        <meta name="keywords" content={props.meta.keywords} />
        <meta name="facebook-domain-verification" content="v1rp4jxjfkqwderk1ntxo8bjfy51hz" />
        <link rel="canonical" href={props.canonicalLink} />
        {props.alternativeLinks.map((x, i) => (
          <link key={i} rel="alternate" hrefLang={x.hrefLang} href={x.href} />
        ))}
      </Head>

      <div className="page-content-section">
        <div className="page-content-block">
          <Hero {...props.hero} />
        </div>
      </div>

      <div className="page-content-section background-light">
        <div className="page-content-block">
          <FeaturedServices {...props.featuredServices} />
        </div>
      </div>

      <div className="page-content-section">
        <div className="page-content-block">
          <ShortcutCountryList {...props.shortcutCountryList} />
        </div>
      </div>

      <div className="page-content-section">
        <div className="page-content-block">{hydrated && <ReviewsComponent {...reviewsProps} />}</div>
      </div>

      <div className="page-content-section background-light">
        <div className="page-content-block">
          <TrustedByComponent {...trustedByProps} />
        </div>
      </div>

      <div className="page-content-section is-narrower">
        <div className="page-content-block">
          <ContactComponent {...contactProps} />
        </div>
      </div>
    </div>
  );
};

HomePage.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout
      locale={page.props.locale}
      navBarProps={page.props.navBarProps}
      cookiesPopUpProps={page.props.cookiesPopUpProps}
      footerProps={page.props.footerProps}
      contentLanguageCode={getContentLanguageCode(page.props.locale)}>
      {page}
    </Layout>
  );
};

export async function getStaticProps(context: any): Promise<{ props: IHomePageProps }> {
  const appRoutes = await getRoutes();

  const getLocalizedAppRoute = (actualPath: string) => {
    return appRoutes?.find((x: IAppPage) => x && x.destination.replace(`/${context.locale}`, '') === actualPath && x.language === context.locale)?.source || actualPath;
  };

  const pageUrlParams = {
    'populate[0]': 'meta',
    'populate[1]': 'hero',
    'populate[2]': 'hero.background',
    'populate[3]': 'featuredServices',
    'populate[4]': 'quickAccessTitle',
    'populate[5]': 'quickAccessSubTitle',
    'populate[6]': 'quickAccessShowAllButton',
    'populate[7]': 'trustedBy',
    'populate[8]': 'trustedBy.logos',
    'populate[9]': 'customerReviews',
    'populate[10]': 'customerReviews.logo',
    'populate[11]': 'contactUsForm',
    'populate[12]': 'contactUsFormSuccessMessage',
    'populate[13]': 'contactUsFormErrorMessage',
  };

  const regionsUrlParams = {
    'populate[0]': 'nameTranslations',
    'populate[1]': 'featuredCountries',
    'populate[2]': 'featuredCountries.nameTranslations',
  };

  const trademarkLandingUrlParams = new URLSearchParams({
    'populate[0]': 'country',
    'populate[1]': 'metas',
    'pagination[start]': '0',
    'pagination[limit]': '-1',
  });

  const customerReviewsUrlParams = {
    locale: context.locale,
  };

  // Get page data
  const page = (await fetchStrapi<any>(`/home-page`, pageUrlParams, buildApiKey)).data.attributes as IHomePage;

  if (!!page.hero && page.hero.length > 0) {
    page.hero.forEach((x) => {
      if (!!(x.background as any).data) {
        x.background = flatStrapiObject(x.background);
      }
    });
  }

  // Get regions to show in featured countries
  const regions = flatStrapiCollection(await fetchStrapi<any>(`/country-regions`, regionsUrlParams, buildApiKey)) as ICountryRegion[];

  regions.forEach((x) => {
    if (x.featuredCountries && (x.featuredCountries as any).data && (x.featuredCountries as any).data.length > 0) {
      x.featuredCountries = flatStrapiCollection(x.featuredCountries as any);
    } else {
      x.featuredCountries = [];
    }
  });

  const featuredCountriesIds = regions.map((x) => x.featuredCountries?.map((y) => y.id)).flat();
  featuredCountriesIds.forEach((id: number | undefined) => {
    trademarkLandingUrlParams.append(`filters[country][id][$in]`, id!.toString());
  });

  const trademarkLandingsResult = await fetchStrapi<any>(`/trademark-landings`, trademarkLandingUrlParams, buildApiKey);
  const trademarkLandings: ITrademarkLanding[] = flatStrapiCollection<ITrademarkLanding>(trademarkLandingsResult);

  if (trademarkLandings.length > 0) {
    trademarkLandings.forEach((x) => {
      x.country = flatStrapiObject(x.country as any);
    });
  }

  const reviews = flatStrapiCollection(await fetchStrapi<any>(`/customer-reviews`, customerReviewsUrlParams, buildApiKey)) as ICustomerReview[];

  const trustedBy: ITrustedByComponent = getTranslation(page.trustedBy, context.locale);
  trustedBy.logos = flatStrapiCollection(trustedBy.logos as any).map((x) => keepObjectProperties<IMedia>(x, ['url', 'alternativeText', 'name']));

  const contactMethods = await getContactMethods();

  const customerReviews: ICustomerReviewsComponent = getTranslation(page.customerReviews, context.locale);

  if (customerReviews.logo && (customerReviews.logo as any).data) {
    customerReviews.logo = flatStrapiObject(customerReviews.logo as any);
  }

  const navBarProps: INavBarProps = await populateNavBarProps(context.locale);
  const cookiesPopUpProps: ICookiesPopupProps = await populateCookiesPopUpProps(context.locale);
  const footerProps: IFooterProps = await populateFooterProps(context.locale);

  // Clean up data
  regions.forEach((x) => {
    x.countries = [];
  });

  const trademarkLandingsMinimalData = trademarkLandings.map((x) => ({
    country: { iso: x.country.iso },
    metas: x.metas.filter((y) => y.language === context.locale),
  }));

  // Prepare Hero
  const heroData = getTranslation(page.hero, context.locale);
  const heroProps: IHeroComponentProps = {
    title: heroData.title,
    description: heroData.description,
    buttonLabel: heroData.buttonLabel,
    buttonLink: getLocalizedAppRoute('/countries'),
    backgroundUrl: heroData.background?.formats?.large?.url || heroData.background?.url || '/assets/images/home/map-image.png',
  };

  // Prepare countries list
  const getShortcutCountryListFeaturedCountries = (region: ICountryRegion) => {
    const featuredCountries = region.featuredCountries || [];
    return featuredCountries
      .map((c) => {
        const trademarkLandingSlug = getLocalizedSlugByCountryIso(trademarkLandingsMinimalData, c.iso, context.locale || 'en');
        return {
          name: getTranslation(c.nameTranslations, context.locale || 'en').text as string,
          link: getLocalizedAppRoute(`/countries/${trademarkLandingSlug}`),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  const quickAccess = {
    title: getTranslation(page.quickAccessTitle, context.locale).text as string,
    subTitle: getTranslation(page.quickAccessSubTitle, context.locale).text as string,
    showAllLabel: getTranslation(page.quickAccessShowAllButton, context.locale).text as string,
    regions,
  };

  const shortcutCountryListProps: IShortcutCountryList = {
    title: quickAccess.title,
    description: quickAccess.subTitle,
    showAllLabel: quickAccess.showAllLabel,
    regions: quickAccess.regions
      .map((x) => ({
        name: getTranslation(x.nameTranslations, context.locale || 'en').text as string,
        link: getLocalizedAppRoute('/countries'),
        countries: getShortcutCountryListFeaturedCountries(x),
        order: x.order,
      }))
      .sort((a, b) => a.order - b.order),
  };

  // Prepare Featured Services
  const featuredServices = getTranslation(page.featuredServices, context.locale);
  const featuredServicesProps: IFeaturedServiceProps = {
    title: featuredServices.title,
    subTitle: featuredServices.subTitle,
    description: featuredServices.description,
    services: [
      {
        title: featuredServices.serviceOneTitle,
        description: featuredServices.serviceOneDescription,
        iconPath: '/assets/images/icons/TrademarkSearchIcon.svg',
        link: getLocalizedAppRoute('/trademark-search'),
      },
      {
        title: featuredServices.serviceTwoTitle,
        description: featuredServices.serviceTwoDescription,
        iconPath: '/assets/images/icons/TrademarkRegistrationIcon.svg',
        link: getLocalizedAppRoute('/trademark-registration'),
      },
    ],
  };

  const meta = getTranslation(page.meta, context.locale);
  const alternativeLinks = getAlternateLinks('/', appRoutes);
  const canonicalLink = getCanonicalLink(context.locale || 'en', meta.slug);

  const props: IHomePageProps = {
    // Layout
    navBarProps,
    cookiesPopUpProps,
    footerProps,
    // Page
    locale: context.locale,
    meta,
    hero: heroProps,
    featuredServices: featuredServicesProps,
    shortcutCountryList: shortcutCountryListProps,
    trustedBy,
    customerReviews,
    reviews,
    contactMethods,
    contactUsForm: getTranslation(page.contactUsForm, context.locale),
    contactUsFormSuccessMessage: getTranslation(page.contactUsFormSuccessMessage, context.locale).text as string,
    contactUsFormErrorMessage: getTranslation(page.contactUsFormErrorMessage, context.locale).text as string,
    alternativeLinks,
    canonicalLink,
  };

  return {
    props,
  };
}

export default HomePage;
